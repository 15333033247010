<style scoped>
.small_text_btn {
    color: #707070;
    font: normal normal normal 14px/17px Apple SD Gothic Neo;
}
</style>
<template>
    <v-container class="py-8 px-6" fill-height fluid>
        <v-row align="center" justify="center">
            <v-col class="ml-12 mr-12" style="min-width:1200px; max-width:1350px; max-height: 780px;">
                <v-row>
                    <v-col class="pa-0 ma-0">
                        <div
                            class="pa-4 text-center text-no-wrap rounded-l-xl elevation-6 text-xs-center"
                            style="height:780px; background-image: url('/img/admin_login_bg.png'); background-size: cover; background-repeat: no-repeat; background-position: center center;"
                        >
                            <v-row style="height: 477px; margin-top:50px; padding-left:60px;">
                                <v-col class="text-left">
                                    <img height="60" src="/img/admin_logo.png"/>
                                </v-col>
                            </v-row>
                            <v-row style="height: 87px;">
                                <v-col class="text-left mt-n14"
                                       style="color:#ffffff; font: normal normal bold 48px/60px Spoqa Han Sans Neo; letter-spacing: -1.65px; padding-left:60px; width: 416px; height: 137px;">
                                    소통은 편리하게<br />고객관리는 Pro답게
                                </v-col>
                            </v-row>
                            <v-row style="height:69px;" class="mt-7">
                                <v-col class="text-left"
                                       style="font: normal normal normal 21px/34px Spoqa Han Sans Neo; letter-spacing: -0.75px; color: #FFFFFF; padding-left:60px;">
                                    전화 응대부터 고객관리까지<br />콜 Pro에서 시작하세요
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col class="pa-0 ma-0">
                        <div
                            class="pa-4 text-center white text-no-wrap rounded-r-xl elevation-6"
                            style="height:780px; min-width: 750px;"
                        >
                            <v-row>
                                <v-col class="title" style="padding-top: 60px;">
                                    <div
                                        style="font: normal normal bold 40px/28px Spoqa Han Sans Neo; letter-spacing: -1.2px; color: #000000;">
                                        Log in
                                    </div>
                                    <div
                                        style="margin-top: 22px; font: normal normal normal 20px/28px Spoqa Han Sans Neo; letter-spacing: -0.6px; color: #000000;">
                                        Sign in to your account
                                    </div>
                                </v-col>
                            </v-row>
                            <v-container bg grid-list-md text-xs-center>
                                <v-layout row wrap align-center pa-9>
                                    <v-flex>
                                        <v-card flat>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" class="pb-0">
                                                        <div class="text-left"
                                                             style="display: block; font: normal normal bold 18px/28px Spoqa Han Sans Neo; letter-spacing: -0.54px; padding: 0px 65px 0px 65px;">
                                                            아이디 입력
                                                        </div>
                                                        <v-text-field
                                                            outlined
                                                            hide-details
                                                            color="primary"
                                                            :ref="'login_id'"
                                                            placeholder="아이디를 입력해 주세요"
                                                            v-model="loginId"
                                                            style="background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 10px; margin-top: 10px; padding: 0px 65px 0px 65px; width: 100%"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" class="pt-0 mb-0 mt-5">
                                                        <div class="text-left"
                                                             style="display: block; font: normal normal bold 18px/28px Spoqa Han Sans Neo; letter-spacing: -0.54px; padding: 0px 65px 0px 65px;">
                                                            비밀번호 입력
                                                        </div>
                                                        <v-text-field
                                                            outlined
                                                            hide-details
                                                            color="primary"
                                                            :ref="'login_pass'"
                                                            type="password"
                                                            placeholder="비밀번호를 입력해 주세요."
                                                            v-model="loginPass"
                                                            v-on:keydown.enter="doSubmit"
                                                            style="background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 10px; margin-top: 10px; padding: 0px 65px 0px 65px; width: 100%;"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="12" class="text-right">
                                                        <v-btn
                                                            text
                                                            class="small_text_btn"
                                                            @click="openPassword"
                                                            style="font: normal normal bold 13px/20px Apple SD Gothic Neo; letter-spacing: -0.54px; margin-top: 5px; margin-right: 65px;">
                                                            비밀번호 찾기
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions style="padding: 0px 80px 0px 80px;">
                                                <v-btn elevation="0" large v-if="!canSubmit"
                                                       v-on:click="doSubmit"
                                                       style="width: 100%; height:55px; background-color:#DDDDDD; color:#ffffff; font: normal normal bold 20px/37px Apple SD Gothic Neo; letter-spacing: 0px; border-radius: 10px;">
                                                    로그인
                                                </v-btn>
                                                <v-btn elevation="0" large v-else color="blue" dark
                                                       v-on:click="doSubmit"
                                                       style="width: 100%; height:55px; font: normal normal bold 20px/37px Apple SD Gothic Neo; letter-spacing: 0px; border-radius: 10px;">
                                                    로그인
                                                </v-btn>
                                            </v-card-actions>
                                            <v-card-text class="mt-1">
                                                <v-btn text class="small_text_btn text-decoration-underline" @click="goJoin"
                                                       style="font: normal normal bold 18px/37px Apple SD Gothic Neo; letter-spacing: -0.54px; color: #4E87DD;">
                                                    아직 회원이 아니신가요? 회원가입
                                                </v-btn>
                                            </v-card-text>
                                            <v-card-text class="ma-0 pa-0">
                                                <v-row class="mt-5" style="justify-content: center;">
                                                    <main-footer></main-footer>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog v-model="popupModal" persistent max-width="591">
            <v-flex v-if="popupType === 'password'">
                <popup-password v-on:cancel="closePopup" v-on:submit="submitPopupPassword"></popup-password>
            </v-flex>
        </v-dialog>
    </v-container>
</template>
<script>
import PopupPassword from '@/components/auth/popup_find';
import MainFooter from '@/components/common/main_footer';

export default {
    name: 'login',
    components: {
        PopupPassword,
        MainFooter
    },
    data: () => ({
        loginId: '',
        loginPass: '',
        popupType: 'none',
        popupModal: false,
        showFooterFlag: false
    }),
    created() {
        this.$store.commit('layoutFlag', false)
        this.$store.commit('menuId', 'login')
        this.$store.commit('setFooterLoaded', 'LOGIN')
    },
    computed: {
        canSubmit() {
            if (this.loginId && this.loginPass) {
                return true
            }
            return false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.login_id.focus()
        })
    },
    methods: {
        goJoin() {
            this.$store.dispatch('join/init')
            this.$router.push('/join')
        },
        doSubmit() {
            let va = this

            if (!this.loginId) {
                let vs = this
                this.$store.dispatch('openAlert', {
                    message: '아이디를 입력해 주세요', closeCb: function () {
                        vs.$nextTick(() => {
                            vs.$refs.login_id.focus()
                        })
                    }
                })
                return
            }
            if (!this.loginPass) {
                let vs = this
                this.$store.dispatch('openAlert', {
                    message: '비밀번호를 입력해 주세요', closeCb: function () {
                        vs.$nextTick(() => {
                            vs.$refs.login_pass.focus()
                        })
                    }
                })
                return
            }
            this.$store.dispatch('login/LOGIN', {
                id: this.loginId,
                pass: this.loginPass,
                scb: function () {
                    va.$router.push('/dashboard')
                }, fcb: function (err) {

                    if (err == 'WAITING_APPROVAL') {
                        va.$router.push('join/wait_approval')
                    } else {
                        va.$store.dispatch('openAlert', {
                            message: '로그인을 실패 했습니다.', closeCb: function () {
                            },
                        })
                    }
                },
            })
        },
        openPassword() {
            this.popupType = 'password'
            this.popupModal = true
        },
        submitPopupPassword() {
            this.closePopup()
        },
        closePopup() {
            this.popupModal = false
            this.popupType = 'none'
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('setFooterLoaded', '')
        next()
    },
}
</script>

