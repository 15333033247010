import commonApi from '@/api/_common.js'

export default {
    sendSms (param, scb, fcb, va) { 
        let reqURL = '/auth/send_sms'
        commonApi.POST(reqURL, param, (data) => { 
            if(scb) {
                scb(data.tran_token, va)
            }
        }, (err) => { 
            console.log('api.auth.sendSms : Fail to execute')                
            if(fcb) { 
                fcb(err, va)
            }
        }, va)       
    },
    verifySms (param, scb, fcb, va) { 
        let reqURL = '/auth/verify_sms'
        commonApi.POST(reqURL, param, (data) => { 
            if(scb) {
                scb(data.verify_result, va)
            }
        }, (err) => { 
            console.log('api.auth.verifySms : Fail to execute')                
            if(fcb) { 
                fcb(err, va)
            }
        }, va)       
    },
    findId (param, scb, fcb, va) { 
        let reqURL = '/auth/find_id'
        commonApi.POST(reqURL, param, (data) => { 
            if(scb) {
                scb(data.user_id, va)
            }
        }, (err) => { 
            console.log('api.auth.findId : Fail to execute')                
            if(fcb) { 
                fcb(err, va)
            }
        }, va)       
    },
    resetPw (param, scb, fcb, va) { 
        let reqURL = '/auth/reset_pw'
        commonApi.POST(reqURL, param, (data) => { 
            if(scb) {
                scb(va)
            }
        }, (err) => { 
            console.log('api.auth.resetPw : Fail to execute')                
            if(fcb) { 
                fcb(err, va)
            }
        }, va)         
    }
    
}