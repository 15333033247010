<style scoped>
.defaultFooter {
    background-color: #FFFFFF !important;
}

.modalFooter {
    background-color: #F5F5F5 !important;
}
</style>
<template>
    <v-footer
        padless
        inset
        style="font: normal normal normal 14px/17px Apple SD Gothic Neo;"
        :class="cssFooter"
    >
        <v-row justify="center">
            <v-col
                class="text-center mb-0 pb-0"
                cols="12"
            >
                <v-btn text :class="{ 'ma-0': footerLoaded == 'LOGIN', 'pa-0': footerLoaded == 'LOGIN', 'font-weight-bold': true }" :style="styleAgreeBtn" @click="openAgreeDoc('PRIVATE')">개인정보 취급방침</v-btn>
                <span :class="cssSpan">|</span>
                <v-btn text :class="{ 'ma-0': footerLoaded == 'LOGIN', 'pa-0': footerLoaded == 'LOGIN' }" :style="styleAgreeBtn" @click="openAgreeDoc('SERVICE')">서비스 이용약관</v-btn>
            </v-col>
            <v-col
                :class="cssCopyRights"
                cols="12"
                :style="styleCopyRights"
            >
                © CallGate {{ new Date().getFullYear() }}. All rights Reserved.
            </v-col>
        </v-row>
        <v-dialog v-model="modal" persistent max-width="800" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'agree'">
                <agree-doc-popup :agreeType="agreeType" @close="closePopup"></agree-doc-popup>
            </v-flex>
        </v-dialog>
    </v-footer>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'common_main_footer',
    components: {
        'agreeDocPopup': () => import('@/components/common/popup_agree_doc'),
    },
    data: () => ({
        modal: false,
        dialogMode: 'none',
        agreeType: "SERVICE",
    }),
    computed: {
        ...mapGetters({
            layoutFlag: 'layoutFlag',
            showAgree: 'showAgree',
            footerLoaded: 'footerLoaded'
        }),
        cssSpan() {
            return this.footerLoaded == 'LOGIN' ? 'mr-2 pl-2' : 'mr-3 pl-3'
        },
        styleAgreeBtn() {
            let ret = {}

            ret['font'] = 'normal normal normal 14px/17px Apple SD Gothic Neo'

            if (this.footerLoaded == 'LOGIN') {
                ret['color'] = '#000000'
                ret['letterSpacing'] = '0px'
            } else {
                ret['color'] = '#707070'
            }

            return ret
        },
        cssFooter() {
            let ret = {}

            if (this.footerLoaded == 'LOGIN') {
                ret['mb-0'] = true
                ret['pb-0'] = true
                ret['mt-11'] = true
                ret['defaultFooter'] = true
            } else {
                ret['pb-10'] = true
                ret['pt-5'] = true
                ret['defaultFooter'] = this.layoutFlag
                ret['modalFooter'] = !this.layoutFlag
            }

            return ret
        },
        cssCopyRights() {
            return 'text-center mt-0 pt-0'
        },
        styleCopyRights() {
            let ret = {}

            if (this.footerLoaded == 'LOGIN') {
                ret['color'] = '#000000'
                ret['letterSpacing'] = '0px'
            } else {
                ret['color'] = '#707070'
            }
            return ret
        }
    },
    watch: {
        showAgree() {
            if (this.showAgree.isShow) {
                this.openAgreeDoc(this.showAgree.type)
            }
        }
    },
    methods: {
        openAgreeDoc(t) {
            this.agreeType = t
            this.dialogMode = 'agree'
            this.modal = true
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'NONE'
            this.$store.commit('setAgree', {'type': '', 'isShow': false})
        }
    }
};
</script>

