<template>
    <v-card hover>   
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row no-gutters>
                <v-col class="pt-1 pl-7 text-h5 font-weight-bold" v-if="popupMod == 'PW'">비밀번호 찾기</v-col>
                <v-col class="pt-1 pl-7 text-h5 font-weight-bold" v-if="popupMod == 'ID'">아이디 찾기</v-col>
            </v-row>        
        </v-card-title>

        <subview-find-pw v-if="popupMod == 'PW'" :ref="'subviewFindPw'" @cancel="closePopup"></subview-find-pw>
        <subview-find-id v-if="popupMod == 'ID'" :ref="'subviewFindId'" @cancel="closePopup"></subview-find-id>

        <v-card-actions class="ma-0 pt-3 pr-5 pl-0 pb-3">
            <v-row no-gutters>
                <v-col class="text-left">
                    <v-btn class="ma-2" width="130px" color="primary" text  v-on:click="goFindID()" v-if="popupMod == 'PW'"><span style="color:#555555; font-size:16px;">아이디 찾기</span></v-btn>
                    <v-btn class="ma-2" width="130px" color="primary" text  v-on:click="goFindPW()" v-if="popupMod == 'ID'"><span style="color:#555555; font-size:16px;">비밀번호 찾기</span></v-btn>
                </v-col>
                <v-col cols="8" class="text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text  v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="doSubmit()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>

    </v-card>
    
</template>

<script>
import authApi from '@/api/auth'

export default {
    data: () => ({
        popupMod: 'PW',
    }),
    components: {
        'subviewFindPw': () => import('@/components/auth/subview_find_pw'),
        'subviewFindId': () => import('@/components/auth/subview_find_id'),
    },    
    computed: {
    },    
    mounted() {
    },
    methods: {
        goFindID() { 
            this.popupMod = 'ID'
        },
        goFindPW() { 
            this.popupMod = 'PW'
        },
        closePopup() { 
            this.$emit('cancel')
        },
        submitPopup() { 
            this.$emit('submit')
        },    
        doSubmit () { 
            if(this.popupMod == 'ID') { 
                this.$refs.subviewFindId.doSubmit()
            } else if(this.popupMod == 'PW') { 
                this.$refs.subviewFindPw.doSubmit()
            }
        } 
    }
}
</script>
